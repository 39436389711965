.edit-item {
  @import "./assignee";
  @import "./checklist";
  @import "./comments";
  @import "./description";
  @import "./history";
  @import "./list";
  @import "./name";
  @import "./priority";
  @import "./linked-tasks";

  display: flex;

  margin: 0 0;
  padding: 0 8px;

  width: 100%;
  height: 100%;

  .column {
    padding: 16px;
  }

  .column.column-1 {
    width: 100%;
    overflow-y: scroll;
  }

  .column.column-2 {
    width: 300px;
  }
}
