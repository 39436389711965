.checklist {
  display: block;
  margin: 0 0 32px;
  padding: 16px 0;

  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  h1 {
    font-size: 16px;
    margin: 0 0 16px;
  }

  ul {
    display: block;
    li {
      display: block;
      margin: 0 0 8px;
      list-style: none;
      .item {
        display: flex;

        font-size: 0;

        border-radius: 4px;
        overflow: hidden;

        user-select: none;

        .description {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
          padding: 0 16px 0;
          flex: 1;
          cursor: default;

          .wrapper {
            font-size: 14px;
            .text-editor [role="textbox"] {
              padding: 0;
              border: none;
              line-height: 16px;
            }

            p {
              margin: 8px 0 8px;
            }
          }
        }

        .checkbox,
        .actions {
          display: flex;
          margin-left: auto;

          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;

          align-items: stretch;

          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          .wrapper {
            display: flex;
            height: 100%;
            .action {
              display: flex;
              width: 32px;
              height: 100%;
              text-align: center;
              cursor: pointer;
              align-items: center;

              &:hover {
                background: #eee;
              }

              &.move {
                cursor: move;
              }

              .material-icons {
                margin: 0 auto;
                font-size: 18px;
                line-height: 32px;
              }
            }
          }
        }

        .checkbox {
          border: none;

          .wrapper {
            .action {
              background: #eee;
              .material-icons {
                font-size: 20px;
                opacity: 0.25;
              }

              &:hover {
                .material-icons {
                  opacity: 0.5;
                }
              }
            }
          }
        }

        &.is-checked {
          .checkbox {
            .wrapper {
              .action {
                background: #00a3d9;
                .material-icons {
                  opacity: 1;
                  color: #fff;
                }
                &:hover {
                  .material-icons {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
