.dialog-confirm {
  padding: 24px;
  h1 {
    margin: 0 0 16px;
    padding: 0;
    font-size: 18px;
  }

  p {
    margin: 0 0 16px;
  }

  .buttons {
    margin: 0 -4px;
    button {
      margin: 0 4px;
    }
  }
}
.dialog-container {
  display: flex;

  width: 100vw;
  height: 100vh;

  align-items: center;

  position: fixed;
  z-index: 1;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.85);

  .dialog-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    padding: 64px;

    overflow-y: scroll;

    .dialog {
      display: flex;
      flex-direction: column;

      margin: 0 auto;

      background: #fff;

      .dialog-header {
        display: flex;

        width: 100%;
        height: 48px;

        line-height: 48px;

        background: #00a3d9;
        color: #fff;

        padding: 0 24px;

        .title {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;

          span {
            display: inline-block;
            text-transform: none;
            padding-left: 4px;
            font-weight: 400;
          }
        }

        .close {
          width: 32px;
          height: 32px;

          border-radius: 100%;
          background: rgba(0, 0, 0, 0.05);

          text-align: center;
          line-height: 32px;

          font-size: 16px;
          font-weight: 600;

          margin: 8px -16px 0 auto;

          cursor: pointer;
        }
      }

      .dialog-body {
        overflow: hidden;
        overflow-y: auto;

        width: 100%;
        height: 100%;
      }
    }
  }

  &.no-scroll {
    .dialog-wrapper {
      .dialog {
        .dialog-body {
          overflow: hidden;
        }
      }
    }
  }

  &.size-small {
    .dialog-wrapper {
      .dialog {
        margin: 0 auto;
        .dialog-body {
          max-width: 400px;
          max-height: 400px;
        }
      }
    }
  }

  &.size-medium {
    .dialog-wrapper {
      .dialog {
        margin: 0 auto;
        .dialog-body {
          max-width: 600px;
          max-height: 400px;
        }
      }
    }
  }

  &.full-width {
    .dialog-wrapper {
      .dialog {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        .dialog-body {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
