.comment {
  margin: 0 0 8px;
  border-bottom: 1px solid #eee;
  padding: 16px 0 16px;

  .header {
    display: flex;

    line-height: 34px;
    height: 32px;

    overflow: hidden;

    margin: 0 0 16px;

    .user {
      display: flex;
      .picture {
      }

      .name {
        padding: 0 8px;
      }
    }

    .date {
      font-size: 12px;
      opacity: 0.6;
    }
  }

  .body {
    .text-editor {
      margin: 0 0 16px;
    }
  }

  .footer {
    margin: 0 -8px;
    line-height: 32px;
    .confirm {
      display: block;
      span {
        padding-right: 8px;
      }
      padding: 8px 16px;
      background: #eee;
    }
  }
}
