.description {
  label {
    display: block;
    font-size: 16px;
    font-weight: 500;

    margin: 0 0 8px;
  }

  .actions {
    padding: 8px 0;
    margin: 0 -4px;
    button {
      margin: 0 4px;
    }
  }
  .text-editor-output {
    min-height: 40px;
  }
}
