.app > .body {
  > .items {
    padding: 16px;
    height: 100%;
    overflow-y: auto;

    .board {
      .accordion {
        .accordion-title {
          background: #222a37;
          color: #fff;
          border: none;
          border-radius: 4px;
        }

        .accordion-body {
          padding-top: 0;

          .list {
            .item-container {
              .item {
                background: #fff;
                padding: 8px 12px;
                border: 1px solid #eee;
                border-radius: 4px;
                margin: 2px 0;
                .name {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
