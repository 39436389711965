.fieldset {
  margin: 0 0 16px;
  padding: 0 0 8px;

  border-bottom: 1px solid #eee;

  h1 {
    display: block;
    margin: 16px 0;

    font-size: 24px;
  }

  p {
    display: block;
    margin: -12px 0 16px;

    font-size: 12px;
  }

  .body {
  }
}
