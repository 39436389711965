.comments {
  .add {
    margin: 0 0 4px;
    border-bottom: 1px solid #eee;
    padding: 0 0 16px;

    .text-editor {
      margin: 0 0 8px;
    }
  }

  @import "./comment.scss";
}
