.list-holder {
  display: block;

  margin: 0;
  padding: 8px;

  width: 100%;
  height: 100%;

  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    background: #f1f3f5;
    height: 100%;

    overflow: hidden;

    border-radius: 4px;

    user-select: none;

    .title {
      display: block;

      margin: 0;
      padding: 0 16px;

      height: 48px;
      line-height: 52px;

      overflow: hidden;

      font-size: 16px;
      font-weight: 600;

      color: #717d93;
      background: rgba(0, 0, 0, 0.01);

      text-transform: uppercase;

      .count {
        display: inline-block;

        line-height: 24px;
        background: #ddd;

        font-weight: 600;
        font-size: 14px;

        margin: 0 4px;
        padding: 0 8px;

        position: relative;
        top: -2px;

        border-radius: 4px;
      }
    }

    .body {
      display: block;

      margin: 4px;
      padding: 0;

      overflow-y: scroll;
      height: calc(100% - 48px);

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .list {
        display: block;

        margin: -4px 0;
        padding: 0;

        min-height: calc(100% - 24px);
        @import "./card.scss";
      }
    }
  }
}
