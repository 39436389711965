.select-input {
  display: block;

  margin: 0 0 16px;
  label {
    display: block;
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .input-wrapper {
    select {
      width: 100%;
      height: 32px;

      border: 1px solid #eee;
      border-radius: 0;
      outline: none;
    }
  }
}
