.user-picture {
  display: inline-flex;

  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: auto;
  }

  .name {
    line-height: 32px;
    padding: 0 8px;
  }

  .initials,
  .picture {
    display: block;

    width: 32px;
    height: 32px;

    border-radius: 100%;
    overflow: hidden;
    background: black;

    line-height: 32px;
    text-align: center;

    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    color: #fff;
  }
}
