.app > .body {
  > .board {
    width: 100%;
    height: 100%;

    overflow: hidden;

    .lists {
      display: flex;
      margin: 0;
      padding: 8px;
      width: 100%;
      height: 100%;

      overflow: hidden;

      @import "./list.scss";
    }
  }
}
