.input.checkbox {
  display: flex;

  background: #00a3d9;
  cursor: pointer;
  border-radius: 4px;

  align-items: center;
  text-align: center;

  width: 32px;
  height: 32px;

  .material-icons {
    display: flex;
    width: auto;
    height: auto;
    width: 24px;
    height: 24px;

    padding: 0;
    margin: 0 auto;

    color: #fff;

    font-size: 24px;
  }
}
