@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-size: 16px;
  font-weight: 400;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;

  box-sizing: border-box;

  font-family: "Roboto", sans-serif;
}

strong {
  font-weight: 600;
}
