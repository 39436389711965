.linked-tasks {
  margin: 0 0 16px;
  border-bottom: 1px solid #eee;
  padding: 0 0 16px;

  h1 {
    font-size: 16px;
    font-weight: 600;

    margin: 0 0 16px;
  }
  .list {
    .title {
      display: block;

      margin: 0 0 8px;

      font-size: 14px;
      font-weight: 600;
    }

    .items {
      display: block;
      .item {
        display: flex;
        overflow: hidden;
        margin: 0 0 4px;

        align-items: stretch;

        .item-wrapper {
          display: flex;

          border: 1px solid #eee;
          border-radius: 4px;

          width: 100%;

          line-height: 20px;
          padding: 6px 8px;

          cursor: pointer;

          &:hover {
            background: #eee;
          }

          .name {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 15px;

            strong {
              padding: 0 10px 0 0;
            }
          }

          .list {
            margin: 0 0 0 auto;
            > span {
              display: inline-block;

              font-size: 14px;
              font-weight: 600;

              text-transform: uppercase;

              padding: 4px 8px;
              background: #eee;

              border-radius: 4px;
            }
          }
          .material-icons {
            display: block;
            width: 32px;
            height: 28px;
            overflow: hidden;
            background: red;
            text-align: center;
            line-height: 28px;
            border-radius: 100%;
            margin-left: 8px;
            background: #eee;

            &:hover {
              background: #ccc;
            }
          }

          &:hover {
            .list {
              > span {
                background: #ccc;
              }
            }
          }
        }
        &.is-done {
          .item-wrapper {
            .list {
              > span {
                background: #000;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .add {
    display: flex;

    align-items: stretch;

    label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
    }

    .linked-by {
      display: flex;
    }

    .text-input,
    .select-input {
      display: flex;
      margin: 0;
    }

    .select-input {
      margin: 0 8px;
    }

    .text-input {
      width: 100%;
      .input-wrapper {
        width: 100%;
      }
    }

    button {
      margin: 0 0 0 8px;
    }
  }
}
