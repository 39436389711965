.button {
  display: inline-block;

  padding: 0 16px;

  height: 32px;
  line-height: 32px;

  overflow: hidden;

  outline: none;
  border: none;
  background: #2f394b;

  font-size: 13px;
  color: #fff;

  text-transform: uppercase;

  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #262f3d;
  }

  &.is-active {
    background: #222a37;
  }

  &.icon {
    padding: 0;
    font-size: 24px;
  }

  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }

  &.text-inline {
    line-height: 24px;
    height: 24px;
    background: none;
    color: #2f394b;
    padding: 0 8px;

    &:hover {
      background: #262f3d;
      color: #fff;
    }
  }
}
