.tabs {
  .navigation {
    display: flex;
    margin: 0 -4px 16px;
    .navigation-tab {
      display: inline-block;

      margin: 0 4px;
      padding: 6px 10px;

      font-size: 14px;

      background: #eee;
      border-radius: 8px;
      font-weight: 500;

      cursor: pointer;

      &.is-active {
        background: #222a37;
        color: #fff;
      }
    }
  }

  .body {
  }
}
