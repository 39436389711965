.accordion {
  display: block;

  margin: 0 0 16px;

  .accordion-title {
    display: block;
    height: 40px;

    padding: 0 12px;

    line-height: 39px;

    font-size: 14px;
    font-weight: 600;

    background: rgba(0, 0, 0, 0.02);
    border: 1px solid #efefef;

    user-select: none;

    overflow: hidden;
    cursor: pointer;
  }

  .accordion-body {
    display: none;
    padding-top: 16px;

    overflow-x: hidden;
    overflow-y: auto;
  }

  &.is-open {
    .accordion-body {
      display: block;
    }
  }
}
