.card {
  display: block;

  margin: 4px 0;
  padding: 8px;

  background: white;
  border: 1px solid #ededed;
  border-radius: 4px;

  .name {
    display: block;
    padding: 8px;

    margin: 0 0 8px;

    font-size: 16px;
    font-weight: 500;

    color: #283a5c;
  }

  .bottom {
    display: flex;

    padding: 0 4px;

    .priority {
      display: flex;
      padding: 6px 0;
      span {
        width: 6px;
        font-size: 20px;
      }

      &.priority-0 {
        color: #6dd900;
        span:nth-child(1),
        span:nth-child(2) {
          display: none;
        }
      }
      &.priority-1 {
        color: #ff8000;
        span:nth-child(2) {
          display: none;
        }
      }

      &.priority-2 {
        color: #ff0000;
      }
    }

    .assignee {
      margin-left: auto;
    }

    .id {
      line-height: 32px;
    }
  }
}
