.app > .body {
  > .accounts {
    h1 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 16px;
    }
    padding: 16px;

    table {
      width: 100%;
      text-align: left;

      thead {
        background: #2f394b;

        tr {
          th {
            &:first-child {
              border-left: 2px solid #222a37;
            }

            font-size: 14px;
            line-height: 48px;

            border-top: 2px solid #222a37;
            border-bottom: 2px solid #222a37;
            border-right: 2px solid #222a37;

            color: #fff;

            padding: 0 16px;
          }
        }
      }

      tbody {
        tr {
          td {
            &:first-child {
              border-left: 2px solid #eee;
            }

            line-height: 40px;

            border-bottom: 2px solid #eee;
            border-right: 2px solid #eee;

            padding: 0 16px;
          }
        }
      }
    }
    .actions {
      .button-icon {
        display: inline-flex;
        margin: 0 2px;
        position: relative;
        top: 5px;
      }
    }
  }
  .footer {
    padding-top: 16px;
  }
}
