.app {
  display: flex;

  width: 100%;
  height: 100vh;

  flex-direction: column;

  overflow: hidden;

  > .header {
    display: flex;
    width: 100%;
    height: 64px;

    background: #344054;

    .logo {
      display: block;

      font-size: 24px;
      font-weight: 100;

      line-height: 64px;

      padding: 0 16px;

      color: #fff;
    }

    .user-holder {
      display: inline-flex;
      margin: 8px;
      margin-left: auto;
      padding: 8px;
      line-height: 32px;
      height: 48px;
      border-radius: 20px;

      background: rgba(0, 0, 0, 0.08);
      cursor: pointer;

      color: #fff;
      .name {
        padding: 0 8px;
        font-size: 14px;
      }
      span {
        opacity: 0.25;
        font-size: 18px;
        font-weight: 300;
      }

      a {
        font-size: 14px;
        padding: 0 8px;
        font-weight: 400;
      }
    }

    .navigation {
      display: flex;
      padding-left: 16px;

      > button {
        margin: 16px 8px;
      }

      .navigation-board {
        display: flex;
        margin: 0 6px;
        button {
          margin: 16px 2px;
        }
      }
    }
  }

  > .body {
    flex: 1;
    width: 100%;
    height: 100%;

    // overflow-y: scroll;
    overflow: hidden;
  }
}
