.text-input {
  display: block;

  margin: 0 0 16px;
  label {
    display: block;
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: 600;
  }
  .input-wrapper {
    input {
      width: 100%;
      height: 32px;
      padding: 0 8px;
      border: 1px solid #eee;
      background: white;
      outline: none;

      &:active,
      &:focus {
        border: 1px solid #ccc;
      }
    }
  }
}
