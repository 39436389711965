.name {
  .content-editable {
    display: block;

    width: 100%;
    height: auto;

    font-size: 18px;
    font-weight: 500;

    padding: 0 0 16px;
    border-bottom: 1px solid #eee;
    margin: 0 0 16px;
  }
}
