.view-profile {
  padding: 0 24px 32px;
  height: 100%;

  .fieldset:first-child {
  }

  p.message {
    font-size: 12px;
    font-weight: 600;

    &.bad {
      color: red;
    }

    &.good {
      color: green;
    }
  }

  .upload {
    display: flex;
    width: 100%;

    overflow: hidden;

    .image {
      width: 25%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .uploader {
      flex: 1;
      padding: 0 0 0 16px;

      input {
        width: 100%;
        margin: 0 0 16px;
      }
    }
  }
}
