.text-editor,
.text-editor-output {
  position: relative;

  font-size: 14px;

  [role="textbox"] {
    border: 1px solid #fff;
    background: #fff;

    border: 1px solid #eee;
    border-top: none;
    padding: 16px;
  }

  img {
    width: 50% !important;
    height: auto;
  }

  p,
  div[data-block] {
    margin: 0 0 16px;
  }

  ul,
  ol {
    margin: 0 0 16px 32px;
  }

  code {
    background: #ccc;
  }

  blockquote {
    margin: 0 0 32px;
    width: 100%;
    font-style: italic;
    color: #555555;
    padding: 16px 30px 16px 32px;
    border-left: 8px solid #ccc;
    line-height: 1.6;
    position: relative;
    background: #ededed;
  }

  img {
    margin: 0 0 16px;
  }

  .image-container {
    position: relative;
    button {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      border: none;
      outline: none;
      border-radius: 100%;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.75);
      }
    }
  }

  blockquote::before {
    content: "\201C";
    color: #ccc;
    font-size: 40px;
    position: absolute;
    left: 10px;
    top: -10px;
  }

  blockquote::after {
    content: "";
  }

  .toolbar {
    display: flex;

    background: #fff;
    border: 1px solid #eee;

    padding: 4px 2px;

    button {
      display: block;

      width: 32px;
      height: 32px;

      padding: 8px;
      margin: 0 2px;

      background: none;
      border: none;
      outline: none;

      background: #eee;

      cursor: pointer;

      font-size: 14px;
      font-weight: bold;
      line-height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: #efefef;
      }
    }
  }
}

.text-editor-output {
  user-select: none;
}
