.app > .header .navigation .navigation-board {
  .archive-board-action {
    display: block;

    padding: 24px;

    min-width: 300px;

    p {
      margin: 0 0 16px;
    }

    .pending-items {
      > span {
        display: block;
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 16px;
      }
      label {
        display: block;

        margin: 0 0 16px;

        cursor: pointer;

        input {
          margin-left: 16px;
        }

        span {
          padding: 0 8px;
          font-size: 14px;
          font-weight: 400;
        }

        select {
          display: block;

          margin: 8px 0 8px 32px;
          height: 32px;

          border: 1px solid #eee;

          width: calc(100% - 32px);
        }
      }
    }
    .buttons {
      padding: 8px 0 0;
      margin: 0px -4px 0;
      button {
        margin: 0 4px;
      }
    }
  }
}
