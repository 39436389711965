.history {
  .item {
    margin: 0 0 16px;

    font-size: 14px;

    .date {
      padding: 4px 0 0;
      font-size: 12px;
      font-style: italic;
      opacity: 0.8;
    }
  }
}
