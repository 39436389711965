.task-search {
  display: flex;
  flex: 1;

  position: relative;

  user-select: none;

  .fake-input {
    display: flex;

    width: 100%;
    border: 1px solid #eee;

    .text-input {
      width: 100%;
      input {
        border: none;
      }
    }

    .selected-tasks {
      display: flex;
      ul {
        display: flex;
        li {
          display: flex;
          padding: 0 2px;
          align-items: center;
          .item {
            display: inline-flex;
            align-items: center;

            padding: 4px 6px;

            background: #eee;
            white-space: nowrap;

            font-size: 14px;

            cursor: pointer;
            border-radius: 4px;

            .material-icons {
              background: #ddd;
              border-radius: 100%;
              padding: 2px;
              margin-left: 4px;

              &:hover {
                background: #333;
                color: #fff;
              }
            }

            &:hover {
              background: #ccc;
            }
          }
        }
      }
    }
  }

  .found-tasks {
    display: block;

    position: absolute;

    top: 32px;

    background: #fff;
    border: 1px solid #eee;

    z-index: 1;

    width: 100%;
    max-height: 180px;

    overflow-y: auto;

    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
    ul {
      li {
        line-height: 32px;
        border-bottom: 1px solid #eee;
        cursor: pointer;

        &:hover {
          background: #efefef;
        }

        .item {
          .name {
            padding: 0 8px;
            font-size: 14px;
            strong {
            }
          }
        }
      }
    }
  }
}
